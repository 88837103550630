import { useLocation } from "react-router-dom";

import PAGES from '../pages.json';

export function GetPath() {

    return useLocation().pathname.replace('/', '');
}

export function GetPage() {

    const page = GetPath();
    const pages: any = PAGES;

    const children = page.split('/');

    if (children.length > 1) {
        return pages[children[0]]['childrens'][children[1]] || pages['/'];
    }

    return pages[page] || pages['/'];
}

export function GetPages() {

    const p: any = PAGES;
    const pages: any = [];
    for (const path in PAGES) {
        pages.push({
            path: path,
            title: p[path].title,
            video: p[path].video,
            childrens: p[path].childrens ?? {},
        })
    }

    // console.log(pages)

    return pages;
}
