import { Link } from 'react-router-dom';
import { GetPages, GetPath } from "../../utils/Pages";
import { Container, Content } from './styles';

export function Steps() {

    const pages = GetPages();
    const path = GetPath() || '/';

    return (
        <Container>
            <Content>
                <div className="nav">
                    <h2>Conheça o Food Checker:</h2>
                    <div className="steps">
                        <ol>
                            {pages.map((item: any, index: number) =>
                                <li key={index} className={path === item.path ? 'active' : ''}>
                                    <Link to={`/${item.path !== '/' ? item.path : ''}`}>
                                        {item.title}
                                    </Link>
                                    {Object.keys(item.childrens).length > 0 &&
                                    <div className="steps-childrens">
                                        <ol>
                                            {Object.keys(item.childrens).map((item2: any, index2: number) =>
                                                <li key={`${index}-${index2}`}
                                                    className={path === `${item.path}/${item2}` ? 'active' : ''}>
                                                    <Link to={`/${item.path}/${item2}`}>
                                                        {item.childrens[item2].title}
                                                    </Link>
                                                </li>
                                            )}
                                        </ol>
                                    </div>
                                    }
                                </li>
                            )}
                        </ol>
                    </div>
                </div>
            </Content>
        </Container>
    )
}
