import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  :root {
    --primary: #673C99;
    --secondary: #cccccc;
    --success: #26B484;
    --warning: #ff9d41;
    --danger: #990000;
    --text-title: #181c20;
    --text-body: #555555;
    --text-light: #f0f2f5;
    --text-light-secondary: #b2b2b2;
    --background: #f0f2f5;
    --shape: #ffffff;
    --border: #cccccc;
    --border-secondary: #383838;
    --bg-black: #101317;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  html {
    @media (max-width: 1080px) {
      font-size: 93.75%;
    }

    @media (max-width: 720px) {
      font-size: 87.5%;
    }
  }

  body {
    background: var(--background);
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: var(--text-body);
  }

  h1, h2, h3, h4, h5, h6, strong, .bold {
    font-weight: 600;
    color: var(--text-title);
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: .6;
    cursor: not-allowed;
  }
`;
