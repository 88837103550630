import styled from "styled-components";

export const Container = styled.header`
    
`;

export const Content = styled.div`
  max-width: 1120px;
  margin: 0 auto;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  
  .nav {
    background: var(--shape);
    border: 1px solid var(--border);
    width: 100%;
    padding: 2rem 4rem;
    border-radius: 4px;
    margin-top: -1rem;

    @media (max-width: 767px) {
      padding: 2rem 1.2rem 2rem 3.8rem;
    }

    h2 {
      color: var(--success);
      text-align: center;
      margin-bottom: 1rem;

      @media (max-width: 767px) {
        text-align: left;
        font-size: 1.4rem;
      }
    }
  }

  .steps {
    display: flex;
    justify-content: center;
    
    ol {
      
      li {
        line-height: 1.6;
        font-size: 1.2rem;
        font-weight: 300;
        padding-left: .2rem;
        margin-bottom: 1rem;
        list-style: decimal-leading-zero;

        @media (max-width: 767px) {
          padding-left: 0;
        }
        
        &::marker {
          font-weight: 600;
          font-family: monospace;
        }
        
        a {
          color: var(--color-text);
          border-bottom: 1px solid #b2b2b2;
          text-decoration: none;
        }
        
        &.active > {
          a {
            color: var(--warning);
          }
        }
        
        .steps-childrens {
          
          ol {
          
            li {
              list-style: disc;
              margin-top: 0.5rem;
              margin-left: 2rem;
              margin-bottom: 0;
              padding-left: 0;
        
                &.active > {
                  a {
                    color: var(--warning);
                  }
                }
            }
          }
        }
      }
    }
  }
`;
